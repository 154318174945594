var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", [_vm._v("thay đổi ngôn ngữ")]),
    _c(
      "div",
      _vm._l(_vm.languages, function (entry) {
        return _c(
          "button",
          {
            key: entry.title,
            on: {
              click: function ($event) {
                return _vm.changeLocale(entry.language)
              },
            },
          },
          [
            _c("flag", { attrs: { iso: entry.flag, squared: false } }),
            _vm._v("\n      " + _vm._s(entry.title) + "\n    "),
          ],
          1
        )
      }),
      0
    ),
    _c("div", [_vm._v("sau khi thay đổi")]),
    _c("h1", [_vm._v(_vm._s(_vm.$t("welcomeMsg")))]),
    _c("h1", [_vm._v(_vm._s(_vm.$t("guide")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }